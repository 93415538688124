import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useContext } from "react";
import Img from 'gatsby-image'
import Layout from '../components/Layout';
import SEO from "../components/SEO"
import Theme, { theme } from "@pagerland/themes/src/RealEstate";

import {
    Footer,
    SingleCourse,
    Register,
    Navbar,
    Reviews,
    Course,
    WelcomeCourses,
} from "@pagerland/themes/src/RealEstate/containers";

import PropTypes from "prop-types";

export const query = graphql`
query CoursePage($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        slug
        title
        text
        dates
        thumb {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }`


export default function CourseDetails({ url, data }) {
    console.log(data)
    const pageBody = data.markdownRemark.html
    const title = data.markdownRemark.frontmatter.title
    const slug = data.markdownRemark.frontmatter.slug
    const text =  data.markdownRemark.frontmatter.text
    const dates =  data.markdownRemark.frontmatter.dates
    const featuredImg = data.markdownRemark.frontmatter.thumb.childImageSharp.fluid
    console.log(slug)
    console.log(featuredImg)
    const section = [
        {
            ImgProps: {
                src: featuredImg.src,
                srcSet: featuredImg.srcSet,
            },
            title: title,
            text: text,
        }
    ]
    return (
        <Theme>
            <Helmet>
                <link href={theme.typography.googleFont} rel="stylesheet" />
                <meta name="theme-color" content={theme.colors.primary} />
            </Helmet>
            <SEO title="Montessori" />
            <Navbar />
            <WelcomeCourses name="" />

            <Layout >
                <div name="about" dangerouslySetInnerHTML={{ __html: pageBody }} />
            </Layout>

            <SingleCourse name="course" sections={section} />
            <Register name="register" courseName={title} courseDates={dates}/ >
            <Reviews name="reviews" />
            <Course name="venue" />  
            <Footer name="footer" />
        </Theme>
    )
}


CourseDetails.propTypes = {
    url: PropTypes.string,
};

CourseDetails.defaultProps = {
    url: "https://www.instagram.com/practicalmontessori/",
};